<!-- eslint-disable vue/valid-v-model -->
<template>
  <div class="new-site-container">
    <section class="mb-8 d-flex justify-space-between">
      <div class="d-flex align-center">
        <h2 class="main-title">
          {{ isEdit && siteData ? siteData.name : "New site" }}
        </h2>
      </div>
      <div>
        <v-btn color="primary" class="px-2 mr-2" @click="backToSites">
          <v-icon class="mr-2 mt-1" color="white">mdi-arrow-left</v-icon>Back to
          sites
        </v-btn>
        <v-btn
          v-if="isEdit && readOnly"
          color="primary"
          class="px-3 white--text mr-2"
          @click="editSite"
        >
          <v-icon class="mr-2 white--text">mdi-pencil</v-icon>Edit site
        </v-btn>
      </div>
    </section>
    <v-form
      ref="form"
      v-model="formValid"
      class="form_container"
      @submit.prevent
      :disabled="isEdit && readOnly"
    >
      <!-- STEP 1 -->
      <v-expansion-panels class="mb-6" v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header
            expand-icon="mdi-menu-down"
            :disable-icon-rotate="false"
            ><h4
              :class="{
                'error--text': !isSiteInfoPanelValid,
              }"
            >
              Site info
            </h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <div v-if="editLoading" class="d-flex align-center justify-center">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <v-row v-else>
              <v-col cols="6">
                <div class="d-flex">
                  <v-combobox
                    v-model="form.org"
                    :items="orgs"
                    item-text="name"
                    label="Org *"
                    outlined
                    dense
                    clearable
                    required
                    :rules="rules.rule('Org') && [rules.three]"
                    @input="
                      form.org =
                        typeof form.org === 'string'
                          ? form.org[0].toUpperCase() + form.org.slice(1)
                          : form.org
                    "
                  ></v-combobox>
                  <v-btn
                    @click="addOrg(form.org)"
                    :disabled="
                      form.org === null ||
                      (form.org &&
                        (form.org.id !== undefined || form.org.length < 3))
                    "
                    :loading="orgsLoading.addNewOrg || orgsLoading.getAllOrgs"
                    color="primary"
                    class="ml-2"
                    >Add new org</v-btn
                  >
                </div>
                <v-text-field
                  v-model="form.site"
                  label="Site name *"
                  outlined
                  dense
                  clearable
                  required
                  :rules="rules.rule('Site name')"
                  :disabled="
                    form.org === null || (form.org && form.org.id === undefined)
                  "
                ></v-text-field>
                <v-text-field
                  v-model="averageLatLong.averageLat"
                  label="Latitude *"
                  outlined
                  dense
                  type="number"
                  disabled
                ></v-text-field>
                <v-text-field
                  v-model="averageLatLong.averageLong"
                  label="Longitude *"
                  outlined
                  dense
                  type="number"
                  disabled
                ></v-text-field>
                <v-text-field
                  v-model="form.location"
                  label="Location"
                  outlined
                  dense
                  clearable
                  :disabled="
                    form.org === null || (form.org && form.org.id === undefined)
                  "
                ></v-text-field>
                <v-select
                  v-model="form.timeZone"
                  :items="timeZones"
                  item-text="name"
                  item-value="value"
                  label="Time zone"
                  outlined
                  dense
                  clearable
                  :disabled="
                    form.org === null || (form.org && form.org.id === undefined)
                  "
                />
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="form.powerPrice"
                      label="Power price (USD)"
                      outlined
                      dense
                      clearable
                      type="number"
                      :rules="[rules.siteInfo.powerPrice]"
                      :disabled="
                        form.org === null ||
                        (form.org && form.org.id === undefined)
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col
                    :class="{
                      'error-highlight': !siteInfoDateValid,
                    }"
                  >
                    <date-picker
                      type="date"
                      v-model="form.powerPriceDate"
                      class="mr-2 date-picker powerprice"
                      :disabled="
                        form.org === null ||
                        (form.org && form.org.id === undefined) ||
                        (isEdit && readOnly)
                      "
                    >
                      <template v-slot:content="slotProps">
                        <calendar-panel
                          :value="slotProps.value"
                          @select="slotProps.emit"
                        ></calendar-panel>
                      </template>
                    </date-picker>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="form.staleTime"
                      label="SCADA stale time (hr)"
                      outlined
                      dense
                      clearable
                      :rules="[rules.siteInfo.scada]"
                      type="number"
                      :disabled="
                        form.org === null ||
                        (form.org && form.org.id === undefined)
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="form.staleTime10m"
                      label="10m data stale time (hr)"
                      outlined
                      dense
                      clearable
                      :rules="[rules.siteInfo.scada]"
                      type="number"
                      :disabled="
                        form.org === null ||
                        (form.org && form.org.id === undefined)
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="form.staleTimeEvent"
                      label="Event data stale time (hr)"
                      outlined
                      dense
                      clearable
                      :rules="[rules.siteInfo.scada]"
                      type="number"
                      :disabled="
                        form.org === null ||
                        (form.org && form.org.id === undefined)
                      "
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- STEP 2 -->
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="mdi-menu-down"
            ><h4><!-- Site details -->Notes</h4></v-expansion-panel-header
          >
          <v-expansion-panel-content
            ><v-md-editor
              v-model="form.notes"
              height="400px"
              left-toolbar="bold italic strikethrough ul ol link image"
              v-if="!readOnly || !isEdit"
              mode="edit"
            />
            <vue-markdown
              v-if="isEdit && readOnly"
              :breaks="false"
              :anchor-attributes="anchorAttrs"
              :source="form.notes"
            />
          </v-expansion-panel-content>
          <v-expansion-panel-content v-if="false">
            <!-- Hide for first iteration -->
            <h5>Reference Info</h5>
            <div v-if="editLoading" class="d-flex align-center justify-center">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <v-row v-else>
              <v-col cols="6">
                <v-text-field
                  v-model="form.controlSystem"
                  label="Control system"
                  outlined
                  dense
                  clearable
                ></v-text-field>
                <v-text-field
                  v-model="form.windSensors"
                  label="Wind & direction sensors"
                  outlined
                  dense
                  clearable
                ></v-text-field>
                <v-text-field
                  v-model="form.primarySecondary"
                  label="Usage of primary vs. secondary"
                  outlined
                  dense
                  clearable
                ></v-text-field>
                <h5>Received docs</h5>
                <v-text-field
                  v-model="form.driveLink"
                  label="Location (drive link)"
                  outlined
                  dense
                  clearable
                ></v-text-field>
                <h5>Key background notes</h5>
                <v-select
                  label="Park controller"
                  v-model="form.parkController"
                  :items="yesOrNo"
                  hide-details
                  outlined
                  dense
                  clearable
                  class="mb-6"
                >
                </v-select>
                <v-select
                  label="Met towers"
                  v-model="form.metTowers"
                  :items="yesOrNo"
                  hide-details
                  outlined
                  dense
                  clearable
                  class="mb-6"
                >
                </v-select>
                <v-text-field
                  v-model="form.apm"
                  label="APM"
                  outlined
                  dense
                  clearable
                ></v-text-field>
                <v-text-field
                  v-model="form.highComponentFailures"
                  label="Known high component failures"
                  outlined
                  dense
                  clearable
                ></v-text-field>
                <v-text-field
                  v-model="form.curtailments"
                  label="Curtailments or export limitations"
                  outlined
                  dense
                  clearable
                ></v-text-field>
                <v-select
                  label="Wind sector management"
                  v-model="form.windSectorManagement"
                  :items="yesOrNo"
                  hide-details
                  outlined
                  dense
                  clearable
                  class="mb-6"
                >
                </v-select>
                <v-select
                  label="Turbine derating proactively for maintenance"
                  v-model="form.deratingProactively"
                  :items="yesOrNo"
                  hide-details
                  outlined
                  dense
                  clearable
                  class="mb-6"
                >
                </v-select>
                <v-select
                  label="Repowered site"
                  v-model="form.repoweredSite"
                  :items="yesOrNo"
                  hide-details
                  outlined
                  dense
                  clearable
                  class="mb-6"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  v-model="form.otherKnownIssues"
                  outlined
                  label="Other known issues on site"
                  clearable
                ></v-textarea>
                <v-textarea
                  v-model="form.hardwareUpgrades"
                  outlined
                  label="Hardware upgrades on turbines"
                  clearable
                ></v-textarea>
                <v-textarea
                  v-model="form.softwareUpgrades"
                  outlined
                  label="Software upgrades on turbines"
                  clearable
                ></v-textarea>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- STEP 3 -->
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="mdi-menu-down"
            ><h4
              :class="{
                'error--text': !isTurbinePanelValid,
              }"
            >
              Turbines
            </h4></v-expansion-panel-header
          >
          <v-expansion-panel-content eager>
            <div v-if="editLoading" class="d-flex align-center justify-center">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <v-row>
                <v-col cols="2">
                  <div>
                    Turbine count:
                    {{ form.turbines ? form.turbines.length : 0 }}
                  </div>
                  <div style>
                    Total capacity:
                    <span class="text-no-wrap">{{ totalCapacity }} MW</span>
                  </div>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    @input="
                      form.orgAbbrev = form.orgAbbrev
                        ? form.orgAbbrev.toUpperCase()
                        : ''
                    "
                    v-model="form.orgAbbrev"
                    label="Org abbreviation"
                    required
                    outlined
                    dense
                    clearable
                    @blur="handleAbbrevBlur"
                    validate-on-blur
                    :maxlength="5"
                    :rules="[rules.turbines.abbrev, rules.abbrev]"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    @input="
                      form.siteAbbrev = form.siteAbbrev
                        ? form.siteAbbrev.toUpperCase()
                        : ''
                    "
                    v-model="form.siteAbbrev"
                    label="Site abbreviation"
                    required
                    outlined
                    dense
                    clearable
                    @blur="handleAbbrevBlur"
                    validate-on-blur
                    :maxlength="5"
                    :minlength="3"
                    :rules="[rules.turbines.abbrev, rules.abbrev]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div
                v-show="
                  form.state.includes('active-') &&
                  form.turbines.length === 0 &&
                  !isTurbinePanelValid &&
                  (!readOnly || (!isEdit && readOnly))
                "
                class="error--text"
              >
                Please add at least one turbine.
              </div>
              <div
                v-show="
                  form.state.includes('active-') &&
                  form.turbines.length > 0 &&
                  !isTurbinePanelValid &&
                  (!readOnly || (!isEdit && readOnly))
                "
                class="error--text"
              >
                Please fill in all fields.
              </div>
              <DataTable
                className="no-background turbine-table"
                :headers="turbinesHeader"
                :items="form.turbines"
                itemKey="id"
                :itemsPerPage="-1"
                emptyDataText="Please add turbines."
                :hideFooter="true"
              >
                <template v-slot:body="{ items, headers }">
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                      <td v-for="(header, key) in headers" :key="key">
                        <v-autocomplete
                          v-if="header.text === 'Turbine model'"
                          v-model="form.turbines[index].turbine_model"
                          :items="sortedTurbineModels"
                          item-text="fullName"
                          return-object
                          hide-details
                          outlined
                          dense
                          :error="
                            form.turbines.length > 0 &&
                            !form.turbines[index].turbine_model &&
                            form.state.includes('active-') &&
                            ((isEdit && !readOnly) || !isEdit)
                          "
                        >
                        </v-autocomplete>
                        <v-row
                          v-else-if="header.text === 'Turbine name'"
                          class="d-flex align-center turbine-name"
                        >
                          <v-col cols="3">
                            <v-text-field
                              v-model="form.orgAbbrev"
                              class="abbrev-width"
                              :minwidth="100"
                              single-line
                              disabled
                              required
                            ></v-text-field></v-col
                          >-
                          <v-col cols="3">
                            <v-text-field
                              v-model="form.siteAbbrev"
                              class="abbrev-width"
                              single-line
                              disabled
                              required
                            ></v-text-field></v-col
                          >-<v-col cols="5">
                            <v-text-field
                              v-model="item[header.value]"
                              @input="inputTurbineName(item, header.value)"
                              single-line
                              clearable
                              required
                              :error="
                                form.turbines.length > 0 &&
                                !form.turbines[index].customer_name &&
                                form.state.includes('active-')
                              "
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-text-field
                          v-else
                          v-model="item[header.value]"
                          label=""
                          single-line
                          clearable
                          required
                          :error="
                            form.turbines.length > 0 &&
                            (form.turbines[index][header.value] === '' ||
                              form.turbines[index][header.value] === null) &&
                            form.state.includes('active-')
                          "
                        ></v-text-field>
                      </td>
                      <v-icon
                        @click="removeTurbine(index)"
                        class="my-5"
                        :disabled="isEdit && readOnly"
                      >
                        mdi-close
                      </v-icon>
                    </tr>
                  </tbody>
                </template>
              </DataTable>
              <div class="mt-4">
                <v-btn
                  @click="addTurbine"
                  color="primary"
                  :disabled="isEdit && readOnly"
                  >Add turbine</v-btn
                >
                <v-btn
                  color="primary"
                  @click="toggleUploadDialog(true), setCsvType('turbine', null)"
                  class="ml-4"
                  :disabled="isEdit && readOnly"
                >
                  Import CSV
                </v-btn>
                <v-btn
                  color="primary"
                  @click="getAllElevations()"
                  class="ml-4"
                  :disabled="isEdit && readOnly"
                  :loading="loadingElevation"
                  >Get elevations</v-btn
                >
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- STEP 4 -->
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="mdi-menu-down"
            ><h4
              :class="{
                'error--text':
                  (subscriptionsNotComplete || !subscriptionRangesValid) &&
                  (!isEdit || !readOnly) &&
                  form.state.includes('active-'),
              }"
            >
              Subscriptions
            </h4></v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <div v-if="editLoading" class="d-flex align-center justify-center">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <div
                v-if="
                  form.subscriptions.length === 0 &&
                  (!isEdit || !readOnly) &&
                  form.state.includes('active-')
                "
                class="error--text"
              >
                Please add at least one subscription.
              </div>
              <div
                v-else-if="
                  subscriptionsNotComplete &&
                  (!isEdit || !readOnly) &&
                  form.state.includes('active-issue')
                "
                class="error--text"
              >
                Please fill in all fields.
              </div>
              <div v-else-if="!subscriptionRangesValid" class="error--text">
                Subscription date range is invalid.
              </div>
              <DataTable
                className="no-background turbine-table"
                :headers="subscriptionsHeader"
                :items="form.subscriptions"
                itemKey="id"
                :itemsPerPage="-1"
                emptyDataText="Please add a subscription."
                :hideFooter="true"
              >
                <template v-slot:body="{ items, headers }">
                  <tbody>
                    <tr
                      v-for="(item, index) in items"
                      :key="index"
                      :class="{
                        'highlight-row': siteData.current_subscription
                          ? siteData.current_subscription.id ===
                              form.subscriptions[index].id &&
                            !$vuetify.theme.isDark
                          : false,
                        'highlight-row-dark': siteData.current_subscription
                          ? siteData.current_subscription.id ===
                              form.subscriptions[index].id &&
                            $vuetify.theme.isDark
                          : false,
                      }"
                    >
                      <td v-for="(header, key) in headers" :key="key">
                        <v-select
                          v-if="header.text === 'Subscription type'"
                          v-model="form.subscriptions[index].subscription_type"
                          :items="subscriptionTypeChoices"
                          item-text="name"
                          return-object
                          outlined
                          dense
                          clearable
                          hide-details
                          :error="
                            form.state === 'active-issue-detection' &&
                            (form.subscriptions[index].subscription_type
                              .name === null ||
                              !form.subscriptions[index].subscription_type.name)
                          "
                        ></v-select>
                        <date-picker
                          v-else-if="header.text === 'Start date'"
                          type="date"
                          placeholder="Start date"
                          v-model="form.subscriptions[index].startDate"
                          class="mr-2 date-picker"
                          :disabled="isEdit && readOnly"
                          :class="{
                            'error-highlight': !isSubDateValid(
                              form.subscriptions[index].startDate,
                            ),
                          }"
                        >
                          <template v-slot:content="slotProps">
                            <calendar-panel
                              :value="slotProps.value"
                              :disabled-date="
                                (date) => {
                                  if (form.subscriptions[index].endDate) {
                                    return (
                                      date > form.subscriptions[index].endDate
                                    );
                                  }
                                  return false;
                                }
                              "
                              @select="slotProps.emit"
                            ></calendar-panel>
                          </template>
                        </date-picker>
                        <date-picker
                          v-else-if="header.text === 'End date'"
                          type="date"
                          placeholder="End date"
                          v-model="form.subscriptions[index].endDate"
                          class="mr-2 date-picker"
                          :disabled="isEdit && readOnly"
                          :class="{
                            'error-highlight': !isSubDateValid(
                              form.subscriptions[index].endDate,
                            ),
                          }"
                        >
                          <template v-slot:content="slotProps">
                            <calendar-panel
                              :disabled-date="
                                (date) =>
                                  date < form.subscriptions[index].startDate
                              "
                              :value="slotProps.value"
                              @select="slotProps.emit"
                            ></calendar-panel>
                          </template>
                        </date-picker>
                      </td>
                      <v-icon
                        @click="removeSubscription(index)"
                        class="my-5"
                        :disabled="isEdit && readOnly"
                      >
                        mdi-close
                      </v-icon>
                    </tr>
                  </tbody>
                </template>
              </DataTable>
              <div class="mt-4">
                <v-btn
                  @click="addSubscription"
                  color="primary"
                  :disabled="isEdit && readOnly"
                  >Add subscription</v-btn
                >
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- STEP 5 -->
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="mdi-menu-down"
            ><h4
              :class="{
                'error--text': !gearboxCompleteOrNotRequired,
              }"
            >
              Analytics settings
            </h4></v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <div v-if="editLoading" class="d-flex align-center justify-center">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <v-row>
                <v-col cols="6">
                  <v-switch
                    :hideDetails="true"
                    class="d-flex align-center"
                    label="Use generator speed to impute rotor speed"
                    color="primary"
                    v-model="form.imputeRotorSpeed"
                  />
                  <v-text-field
                    v-model="form.gearboxRatio"
                    v-if="form.imputeRotorSpeed"
                    required
                    class="mt-6"
                    label="Gearbox ratio"
                    outlined
                    dense
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- STEP 6 -->
        <v-expansion-panel @change="setPowerCurve">
          <v-expansion-panel-header expand-icon="mdi-menu-down"
            ><h4
              :class="{
                'error--text': !isPowerCurvePanelValid,
              }"
            >
              Contract power curve(s)
            </h4></v-expansion-panel-header
          >
          <v-expansion-panel-content eager>
            <div v-if="editLoading" class="d-flex align-center justify-center">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <div
              v-else-if="
                form.turbines.length === 0 ||
                !form.turbines.some((turbine) => turbine.turbine_model != null)
              "
            >
              <span class="error--text"
                >Please add at least one turbine with a turbine model.</span
              >
            </div>
            <div v-else>
              <v-select
                label="One per"
                @input="inputPowerCurveType"
                v-model="form.contractPowerCurveType"
                item-text="name"
                item-value="value"
                value="Farm/turbine model"
                :items="powerCurveTypes"
                hide-details
                outlined
                dense
                class="mb-6"
              />
              <v-row>
                <v-col>
                  <div
                    v-show="
                      (form.state === 'active-calculations' ||
                        form.state === 'active-issue-detection') &&
                      !isPowerCurvePanelValid &&
                      ((!this.isEdit && this.readOnly) ||
                        (this.isEdit && !this.readOnly))
                    "
                    class="error--text"
                  >
                    Please fill in all fields.
                  </div>
                  <DataTable
                    className="no-background turbine-table"
                    :headers="contractPowerCurveHeader"
                    :items="form.contractPowerCurve"
                    itemKey="id"
                    :itemsPerPage="-1"
                    empty-data-text="Please add power curve."
                    :hideFooter="true"
                  >
                    <template v-slot:body>
                      <tbody>
                        <tr
                          v-for="(item, index) in form.contractPowerCurve"
                          :key="item.id"
                        >
                          <td style="vertical-align: top">
                            <v-text-field
                              v-model="
                                form.contractPowerCurveType === 'turbine'
                                  ? form.contractPowerCurve[index].name
                                  : form.contractPowerCurve[index].turbineModel
                                      .name
                              "
                              label=""
                              single-line
                              disabled
                            ></v-text-field>
                          </td>
                          <td style="vertical-align: top">
                            <v-text-field
                              v-model="
                                form.contractPowerCurve[index].airDensity
                              "
                              label=""
                              single-line
                              clearable
                              required
                              @blur="isPowerCurveComplete"
                              @click:clear="powerCurveIsComplete = false"
                              :error="
                                (form.state === 'active-calculations' ||
                                  form.state === 'active-issue-detection') &&
                                !form.contractPowerCurve[index].airDensity &&
                                ((isEdit && !readOnly) || !isEdit)
                              "
                            ></v-text-field>
                            <div class="d-flex justify-center">
                              <v-btn
                                @click="addAirDensity(item.id)"
                                class="mb-2 mr-2"
                                color="primary"
                                :disabled="isEdit && readOnly"
                                >Add air density</v-btn
                              >
                              <v-btn
                                @click="removeAirDensity(item.id)"
                                class="mb-2 mr-2"
                                color="primary"
                                :disabled="isEdit && readOnly"
                                >Remove air density</v-btn
                              >
                            </div>
                          </td>
                          <td>
                            <DataTable
                              className="no-background turbine-table"
                              :headers="powerCurveHeader"
                              :items="[
                                form.contractPowerCurve[index].powerCurve,
                              ]"
                              itemKey="id"
                              :itemsPerPage="-1"
                              emptyDataText="Please add power curve."
                              :hideFooter="true"
                            >
                              <template v-slot:body>
                                <tbody>
                                  <tr
                                    v-for="(item, i) in form.contractPowerCurve[
                                      index
                                    ].powerCurve.power"
                                    :key="i"
                                  >
                                    <td>
                                      <v-text-field
                                        v-model="
                                          form.contractPowerCurve[index]
                                            .powerCurve.windSpeed[i]
                                        "
                                        label=""
                                        single-line
                                        clearable
                                        type="number"
                                        @blur="isPowerCurveComplete"
                                        @click:clear="
                                          powerCurveIsComplete = false
                                        "
                                        :error="isWindspeedError(index, i)"
                                      ></v-text-field>
                                      <span
                                        v-show="
                                          form.contractPowerCurve[index]
                                            .powerCurve.windSpeed[i] < 0 &&
                                          (!readOnly || (!isEdit && readOnly))
                                        "
                                        class="error--text"
                                        >Wind speed must be positive</span
                                      >
                                    </td>
                                    <td>
                                      <v-text-field
                                        v-model="
                                          form.contractPowerCurve[index]
                                            .powerCurve.power[i]
                                        "
                                        label=""
                                        single-line
                                        clearable
                                        type="number"
                                        @blur="isPowerCurveComplete"
                                        @click:clear="
                                          powerCurveIsComplete = false
                                        "
                                        :error="isPowerError(index, i)"
                                      ></v-text-field>
                                      <span
                                        v-show="
                                          (form.contractPowerCurve[index]
                                            .powerCurve.power[i] < 0 ||
                                            form.contractPowerCurve[index]
                                              .powerCurve.power[i] > 20000) &&
                                          (!readOnly || (!isEdit && readOnly))
                                        "
                                        class="error--text"
                                        >Power must be greater than 0 and less
                                        than 20,000.</span
                                      >
                                    </td>
                                    <v-icon
                                      @click="removePowerCurve(index, i)"
                                      class="my-5"
                                      :disabled="isEdit && readOnly"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </tr>
                                </tbody>
                              </template>
                            </DataTable>
                            <span
                              v-show="
                                isMonotonic[index] && !isMonotonic[index].value
                              "
                              class="error--text"
                              >Wind speed must monotonically increase.<br
                            /></span>
                            <div class="d-flex justify-center">
                              <v-btn
                                @click="addPowerCurve(index)"
                                class="mb-2 mr-2"
                                color="primary"
                                :disabled="isEdit && readOnly"
                                >Add row</v-btn
                              >
                              <v-btn
                                color="primary"
                                @click="
                                  toggleUploadDialog(true),
                                    setCsvType('powerCurve', index)
                                "
                                class="ml-4"
                                :disabled="isEdit && readOnly"
                              >
                                Import CSV
                              </v-btn>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </DataTable>
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="d-flex align-center justify-content-start my-4">
        <v-btn
          color="green"
          :disabled="isSaveDisabled"
          :loading="saveLoading"
          class="px-8 white--text mr-2"
          @click.prevent="save"
          >Save</v-btn
        >
        <v-btn :disabled="!isEdit" outlined class="px-8" @click="cancel">
          Cancel
        </v-btn>
        <div class="ml-4">
          <v-select
            v-model="form.state"
            label="Activation level"
            item-text="text"
            item-value="value"
            outlined
            dense
            hide-details
            :disabled="isEdit && readOnly"
            :items="activationStates"
            @change="changeActivationLevel"
          ></v-select>
        </div>
      </div>
    </v-form>

    <UploadDialog
      v-if="showDialog"
      :isNotSiteDocument="true"
      title="Upload CSV"
      :csvOnly="true"
      @setShowDialog="toggleUploadDialog"
      @setFile="setFile"
      class="turbine-upload"
    />
    <div
      @click="toggleUploadDialog(false)"
      class="backdrop"
      v-if="showDialog"
    ></div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/DataTable";
import UploadDialog from "@/components/UploadDialog";
import DatePicker from "vue2-datepicker";
const { CalendarPanel } = DatePicker;
import timeZones from "@/helpers/timezones";
import VueMarkdown from "vue-markdown-v2";

export default {
  name: "SiteManager",
  components: {
    DataTable,
    UploadDialog,
    DatePicker,
    CalendarPanel,
    VueMarkdown,
  },
  data() {
    return {
      panel: 0,
      rules: {
        rule(name) {
          return [(v) => !!v || `${name} is required`];
        },
        abbrev: (value) =>
          (value && value.length >= 3 && value.length <= 5) ||
          "Must have 3 to 5 characters",
        abbrevOrg: (value) =>
          (value && value.length === 3) || "Must have 3 characters",
        three: (value) =>
          (value && typeof value === "string"
            ? value.length > 2
            : value?.name?.length > 2 || value === undefined) ||
          "Must have at least 3 characters",
        siteInfo: {
          location: true,
          timezone: true,
          powerPrice: true,
          scada: true,
        },
        turbines: {
          abbrev: true,
          customer_name: true,
          turbine_model: true,
          latitude_deg: true,
          longitude_deg: true,
          elevation_m: true,
          year_installed: true,
        },
      },
      form: {
        org: null,
        site: null,
        orgAbbrev: "",
        siteAbbrev: "",
        latitude: null,
        longitude: null,
        location: null,
        timeZone: null,
        powerPrice: null,
        powerPriceDate: new Date(),
        powerPriceId: null,
        staleTime: null,
        staleTime10m: null,
        staleTimeEvent: null,
        notes: "",
        controlSystem: null,
        windSensors: null,
        primarySecondary: null,
        parkController: null,
        metTowers: null,
        apm: null,
        highComponentFailures: null,
        curtailments: null,
        windSectorManagement: null,
        deratingProactively: null,
        repoweredSite: null,
        otherKnownIssues: null,
        hardwareUpgrades: null,
        softwareUpgrades: null,
        turbineCount: null,
        turbines: [],
        subscriptions: [],
        imputeRotorSpeed: false,
        gearboxRatio: null,
        contractPowerCurve: [
          {
            airDensity: null,
            powerCurve: { power: [null], windSpeed: [null] },
            turbineModel: {
              name: "",
            },
          },
        ],
        contractPowerCurveType: "turbine-model",
        driveLink: null,
        state: "",
      },
      yesOrNo: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      turbinesHeader: [
        { text: "Turbine name", value: "customer_name" },
        { text: "Turbine model", value: "turbine_model" },
        { text: "Latitude", value: "latitude_deg" },
        { text: "Longitude", value: "longitude_deg" },
        { text: "Elevation (m)", value: "elevation_m" },
        { text: "Year installed", value: "year_installed" },
      ],
      subscriptionsHeader: [
        { text: "Subscription type", value: "customer_name" },
        { text: "Start date", value: "start_date" },
        { text: "End date", value: "end_date" },
      ],
      contractPowerCurveHeader: [
        {
          text: "Turbine",
        },
        {
          text: "Air density (kg/m^3)",
        },
        {
          text: "Power curve",
        },
      ],
      powerCurveHeader: [
        {
          text: "Wind speed (m/s)",
          value: "contractPowerCurve.powerCurve.windSpeed",
        },
        {
          text: "Power (kW)",
          value: "contractPowerCurve.powerCurve.power",
        },
      ],
      powerCurveTypes: [
        { name: "Farm/turbine model", value: "turbine-model" },
        { name: "Turbine", value: "turbine" },
      ],
      subscriptionTypeChoices: [
        {
          name: "Pulse Basic",
          sow_def_id: 11,
        },
        {
          name: "Pulse",
          sow_def_id: 1,
        },
        {
          name: "Swarm",
          sow_def_id: 10,
        },
      ],
      activationStates: [
        {
          text: "Inactive",
          value: "inactive",
        },
        {
          text: "Data integration",
          value: "active-data-integration",
        },
        {
          text: "Calculations",
          value: "active-calculations",
        },
        {
          text: "Issue detection",
          value: "active-issue-detection",
        },
      ],
      showDialog: false,
      file: null,
      editLoading: false,
      saveLoading: false,
      csvType: null,
      csvIndex: null,
      timeZones,
      readOnly: true,
      changesMade: false,
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
      formValid: false,
      siteInfoDateValid: true,
      powerCurveIsComplete: false,
    };
  },
  computed: {
    ...mapState({
      orgs: (state) => state.orgs.allOrgs,
      loadingElevation: (state) => state.elevation.loading.getElevation,
      elevation: (state) => state.elevation.elevation,
      orgResponse: (state) => state.orgs.orgResponse,
      orgsLoading: (state) => state.orgs.loading.getAllOrgs,
      powerPrice: (state) => state.sites.powerPrice,
      siteData: (state) => state.sites.site,
      siteResponse: (state) => state.sites.siteResponse,
      allTurbineModels: (state) => state.turbineModels.turbineModels,
    }),
    sortedTurbineModels() {
      // eslint-disable-next-line no-undef
      let turbineModels = structuredClone(this.allTurbineModels);
      turbineModels = turbineModels.map((model) => ({
        ...model,
        fullName: `${model.manufacturer.name} ${model.name}`,
      }));
      return turbineModels?.sort((a, b) => {
        return a.fullName?.localeCompare(b.fullName);
      });
    },
    isOrgAbbrevValid() {
      return (
        !this.form.orgAbbrev ||
        (this.form.orgAbbrev.length >= 3 && this.form.orgAbbrev.length <= 5)
      );
    },
    isSiteAbbrevValid() {
      return (
        !this.form.siteAbbrev ||
        (this.form.siteAbbrev.length >= 3 && this.form.siteAbbrev.length <= 5)
      );
    },
    isSiteReadyToSave() {
      return (
        this.form.org &&
        this.form.site &&
        this.isOrgAbbrevValid &&
        this.isSiteAbbrevValid
      );
    },
    isSiteInfoPanelValid() {
      return (
        !this.form.state.includes("active-") ||
        (this.form.org &&
          this.form.site &&
          this.form.powerPrice &&
          this.form.powerPriceDate !== null &&
          this.form.powerPriceDate.toString() !== "Invalid Date")
      );
    },
    isTurbinePanelValid() {
      return (
        !this.form.state.includes("active-") ||
        (!this.turbinesNotComplete &&
          this.isOrgAbbrevValid &&
          this.isSiteAbbrevValid)
      );
    },
    isPowerCurvePanelValid() {
      return (
        !(
          this.form.state === "active-calculations" ||
          this.form.state === "active-issue-detection"
        ) ||
        this.isEdit === this.readOnly ||
        this.powerCurveIsComplete
      );
    },
    isSiteReadyToActivate() {
      return Boolean(
        this.form.org &&
          this.form.site &&
          this.form.powerPrice &&
          this.form.powerPriceDate &&
          this.form.turbines.length > 0 &&
          this.form.contractPowerCurve.length > 0 &&
          !this.powerCurveNotComplete() &&
          !this.turbinesNotComplete &&
          this.gearboxCompleteOrNotRequired &&
          this.form.orgAbbrev?.length >= 3 &&
          this.form.orgAbbrev?.length <= 5 &&
          this.form.siteAbbrev?.length >= 3 &&
          this.form.siteAbbrev?.length <= 5,
      );
    },
    gearboxCompleteOrNotRequired() {
      return this.form.imputeRotorSpeed
        ? this.form.gearboxRatio != null && this.form.gearboxRatio !== ""
        : true;
    },
    subscriptionsNotComplete() {
      if (this.form.state.includes("inactive")) {
        return false;
      }
      let subNotComplete = false;
      if (this.form.subscriptions.length > 0) {
        for (const sub of this.form.subscriptions) {
          if (!sub.subscription_type.name || !sub.startDate || !sub.endDate) {
            subNotComplete = true;
            break;
          }
        }
        return subNotComplete;
      } else {
        return true;
      }
    },
    canSaveTurbines() {
      return this.form.turbines?.length === 0
        ? true
        : !this.turbinesNotComplete;
    },
    turbinesNotComplete() {
      const isEmptyOrNull = (value) => value === "" || value === null;
      if (this.form.state.includes("inactive")) {
        return false;
      } else {
        return (
          this.form.turbines?.length === 0 ||
          this.form.turbines.some(
            ({
              turbine_model,
              customer_name,
              latitude_deg,
              longitude_deg,
              year_installed,
              elevation_m,
            }) =>
              !turbine_model ||
              !customer_name ||
              isEmptyOrNull(latitude_deg) ||
              isEmptyOrNull(longitude_deg) ||
              !year_installed ||
              !elevation_m,
          )
        );
      }
    },
    canSavePowerCurve() {
      if (!this.form.turbines?.length) return true;
      if (!this.form.contractPowerCurve?.length) return true;
      return !this.powerCurveNotComplete();
    },
    isMonotonic() {
      const results = [];
      if (this.form.contractPowerCurve[0]?.powerCurve?.power?.length > 0) {
        for (let i = 0; i < this.form.contractPowerCurve?.length; i++) {
          const windSpeed = this.form.contractPowerCurve[
            i
          ]?.powerCurve?.windSpeed?.map((x) => parseInt(x, 10));
          const sortedWindSpeed = [...windSpeed].sort((a, b) => a - b);

          results.push({
            index: i,
            value: windSpeed.includes(NaN)
              ? true
              : windSpeed.every(
                  (element, index) => element === sortedWindSpeed[index],
                ),
          });
        }
      }
      return results;
    },
    isEdit() {
      return this.$route.name === "EditSite";
    },
    totalCapacity() {
      let totalCapacity = 0;
      if (this.form.turbines?.length > 0) {
        this.form.turbines.forEach((turbine) => {
          totalCapacity += turbine.turbine_model?.rated_power_kw;
        });
      }
      return totalCapacity > 0 ? totalCapacity / 1000 : 0;
    },
    turbineModels() {
      if (this.form.turbines?.length > 0) {
        const turbineModels = [
          ...new Set(
            this.form.turbines.map((turbine) => turbine.turbine_model),
          ),
        ];
        return turbineModels;
      }
      return [];
    },
    turbines() {
      if (this.form.turbines?.length > 0) {
        return this.form.turbines;
      }
      return [];
    },
    contractPowerCurveType() {
      return this.form.contractPowerCurveType;
    },
    averageLatLong() {
      const averages = { averageLat: 0, averageLong: 0 };
      if (this.form.turbines.length > 0) {
        const totalLat = this.form.turbines.reduce((a, b) => {
          return +a + +b["latitude_deg"];
        }, 0);

        const totalLong = this.form.turbines.reduce((a, b) => {
          return +a + +b["longitude_deg"];
        }, 0);
        let sigfig = totalLat?.toString().split(".")[1]?.length;
        averages.averageLat = +(
          Math.round(
            totalLat / this.form.turbines.length + `e+${sigfig ?? 0}`,
          ) + `e-${sigfig ?? 0}`
        );
        sigfig = totalLong?.toString().split(".")[1]?.length;
        averages.averageLong = +(
          Math.round(
            totalLong / this.form.turbines.length + `e+${sigfig ?? 0}`,
          ) + `e-${sigfig ?? 0}`
        );
      }

      return averages;
    },
    isSaveDisabled() {
      const isCommonValid =
        this.isSiteInfoPanelValid && this.isTurbinePanelValid;

      switch (this.form.state) {
        case "active-data-integration":
          if (this.form.subscriptions.length > 0) {
            for (const sub of this.form.subscriptions) {
              if (sub.name || sub.endDate) {
                return (
                  (this.subscriptionsNotComplete && !isCommonValid) ||
                  !this.changesMade
                );
              }
            }
            return !isCommonValid || !this.changesMade;
          } else {
            return !isCommonValid || !this.changesMade;
          }

        case "active-calculations":
          if (this.form.subscriptions.length > 0) {
            return (
              (this.subscriptionsNotComplete &&
                !isCommonValid &&
                !this.isPowerCurvePanelValid) ||
              !this.changesMade
            );
          }
          return (
            !(isCommonValid && this.isPowerCurvePanelValid) || !this.changesMade
          );

        case "active-issue-detection":
          return (
            !(
              isCommonValid &&
              this.isPowerCurvePanelValid &&
              !this.subscriptionsNotComplete
            ) || !this.changesMade
          );

        default:
          return (
            !this.isSiteReadyToSave ||
            (this.isEdit && this.readOnly) ||
            !this.changesMade
          );
      }
    },
    subscriptionRangesValid() {
      if (this.form.subscriptions.length > 0) {
        for (const sub of this.form.subscriptions) {
          if (sub.startDate && sub.endDate) {
            if (sub.startDate > sub.endDate) {
              return false;
            }
          }
        }
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      addNewOrg: "orgs/addNewOrg",
      getAllOrgs: "orgs/getAllOrgs",
      getSidebarOrgs: "app/getOrgs",
      getSite: "sites/getSite",
      getPowerPrice: "sites/getPowerPrice",
      getTurbineModels: "turbineModels/getTurbineModels",
      addNewSite: "sites/addNewSite",
      updateSite: "sites/updateSite",
      getElevation: "elevation/getElevation",
    }),
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16),
      );
    },
    async getAllElevations() {
      for (let i = 0; i < this.form.turbines.length; i++) {
        if (
          this.form.turbines[i].latitude_deg != null &&
          this.form.turbines[i].longitude_deg != null
        ) {
          await this.getElevation({
            lat: this.form.turbines[i].latitude_deg,
            lon: this.form.turbines[i].longitude_deg,
          });
          this.form.turbines[i].elevation_m = this.elevation.elevation_m;
        }
      }
    },
    editSite() {
      this.readOnly = false;
      this.changesMade = false;
    },
    toggleUploadDialog(showDialog) {
      this.showDialog = showDialog;
    },
    setCsvType(type, index) {
      this.csvType = type;
      this.csvIndex = index;
    },
    setFile(file) {
      this.$papa.parse(file.length > 0 ? file[0] : file, {
        header: true,
        skipEmptyLines: true,
        complete: this.onComplete,
      });
      this.changesMade = true;
    },
    onComplete(parsed) {
      if (this.csvType === "turbine") {
        const turbines = this.form.turbines;
        const sortedTurbineModels = this.sortedTurbineModels;
        parsed.data.forEach((turbine) => {
          const index = turbines.findIndex((t) => t.name === turbine.name);
          const customerName =
            turbine?.name?.match(/-/g)?.length > 1
              ? turbine?.name?.match(/(?<=([^-]*-){2}).*/)[0]
              : turbine?.name;
          const turbineModel = sortedTurbineModels.find(
            (turbineModel) => turbineModel.id == turbine.turbine_model_id,
          );
          const updatedTurbine = {
            name: turbine.name,
            customer_name: customerName,
            turbine_model: turbineModel,
            latitude_deg: turbine.latitude_deg,
            longitude_deg: turbine.longitude_deg,
            year_installed: turbine.year_installed,
            elevation_m: turbine.elevation_m,
          };
          if (index !== -1) {
            turbines[index] = { ...turbines[index], ...updatedTurbine };
          } else {
            turbines.push(updatedTurbine);
          }
        });
      } else {
        this.form.contractPowerCurve[this.csvIndex].powerCurve.windSpeed =
          parsed.data.map((pc) => pc.windspeed_mps);
        this.form.contractPowerCurve[this.csvIndex].powerCurve.power =
          parsed.data.map((pc) => pc.realpower_kw);
      }
    },
    backToSites() {
      this.$router.push({ path: "/admin/sites" });
    },
    async addOrg() {
      await this.addNewOrg(this.form.org);
      if (Object.keys(this.orgResponse)?.length > 0) {
        this.form.org = {
          id: this.orgResponse.id,
          name: this.orgResponse.name,
        };
      }
    },
    addTurbine() {
      this.form.turbines.push({
        name: null,
        turbine_model: null,
        latitude_deg: null,
        longitude_deg: null,
        elevation_m: null,
        year_installed: null,
      });
    },
    addSubscription() {
      this.form.subscriptions.push({
        id: null,
        subscription_type: {
          name: null,
          sow_def_id: null,
        },
        endDate: null,
        notes: null,
        startDate: null,
      });
    },
    addPowerCurve(index) {
      this.form.contractPowerCurve[index].powerCurve.windSpeed.push(null);
      this.form.contractPowerCurve[index].powerCurve.power.push(null);
      this.changesMade = true;
    },
    addAirDensity(id) {
      const index = this.form.contractPowerCurve.findIndex(
        (item) => item.id === id,
      );
      const pushContractPowerCurve = (options) => {
        this.form.contractPowerCurve.push({
          id: this.uuidv4(),
          airDensity: null,
          powerCurve: { power: [null], windSpeed: [null] },
          ...options,
        });
        this.isPowerCurveComplete();
      };

      if (this.form.contractPowerCurveType === "turbine-model") {
        if (index !== -1) {
          pushContractPowerCurve({
            turbineModel: {
              name: this.form.contractPowerCurve[index].turbineModel.name,
              id: this.form.contractPowerCurve[index].turbineModel.id,
            },
          });
        }
      }

      if (this.form.contractPowerCurveType === "turbine") {
        const name = this.form.turbines[index]?.name
          ? this.form.turbines[index].name
          : this.form.contractPowerCurve[index]?.name
            ? this.form.contractPowerCurve[index]?.name
            : this.setPCTurbineName(
                this.form.orgAbbrev,
                this.form.siteAbbrev,
                this.form.contractPowerCurve[index].turbine?.customer_name,
              );

        pushContractPowerCurve({ name, turbine: this.form.turbines[index] });
      }

      this.refreshPowerCurve();
    },
    removeAirDensity(id) {
      const index = this.form.contractPowerCurve.findIndex(
        (item) => item.id === id,
      );
      if (this.form.contractPowerCurveType === "turbine-model") {
        const currentTurbineModel =
          this.form.contractPowerCurve[index].turbineModel.name;
        const turbineModelCount = this.form.contractPowerCurve.filter(
          (item) => item.turbineModel.name === currentTurbineModel,
        ).length;
        if (turbineModelCount > 1) {
          this.form.contractPowerCurve.splice(index, 1);
        }
      } else {
        if (this.form.contractPowerCurve.length > 1) {
          this.form.contractPowerCurve.splice(index, 1);
        }
      }
      this.refreshPowerCurve();
      this.isPowerCurveComplete();
    },
    refreshPowerCurve() {
      this.$nextTick(() => {
        this.changesMade = true;
        this.setPowerCurve();
        if (this.form.contractPowerCurve.length > 0) {
          this.form.contractPowerCurve[0].id = this.uuidv4();
        }
        this.$forceUpdate();
      });
    },
    removeTurbine(index) {
      this.form.turbines.splice(index, 1);
    },
    removeSubscription(index) {
      this.form.subscriptions.splice(index, 1);
    },
    removePowerCurve(index, i) {
      if (this.form.contractPowerCurve[index].powerCurve.power?.length > 1) {
        this.form.contractPowerCurve[index].powerCurve.power.splice(i, 1);
        this.form.contractPowerCurve[index].powerCurve.windSpeed.splice(i, 1);
      }
    },
    inputTurbineName(item, headerValue) {
      if (item[headerValue]) {
        item.name = this.setPCTurbineName(
          this.form.orgAbbrev,
          this.form.siteAbbrev,
          item.customer_name,
        ).toUpperCase();
      } else {
        item[headerValue] = "";
      }
    },
    inputPowerCurveType() {
      if (this.form.contractPowerCurveType === "turbine") {
        this.form.contractPowerCurve = [];
        for (let i = 0; i < this.form.turbines?.length; i++) {
          this.form.contractPowerCurve.push({
            id: this.uuidv4(),
            name: this.form.turbines[i]?.name
              ? this.form.turbines[i].name
              : this.setPCTurbineName(
                  this.form.orgAbbrev,
                  this.form.siteAbbrev,
                  this.form.turbines[i].customer_name,
                ),
            turbine: this.form.turbines[i],
            airDensity: null,
            powerCurve: { windSpeed: [null], power: [null] },
          });
        }
      }
      if (
        this.form.contractPowerCurveType === "turbine-model" &&
        this.$route.name !== "EditSite"
      ) {
        this.form.contractPowerCurve = [];
        for (let i = 0; i < this.turbineModels?.length; i++) {
          this.form.contractPowerCurve.push({
            id: this.uuidv4(),
            turbineModel: this.turbineModels[i],
            airDensity: null,
            powerCurve: { windSpeed: [null], power: [null] },
          });
        }
      }
      if (
        this.form.contractPowerCurveType === "turbine-model" &&
        this.$route.name === "EditSite"
      ) {
        // eslint-disable-next-line no-undef
        this.form.contractPowerCurve = structuredClone(
          this.siteData,
        ).contract_power_curves.map((pc) => ({
          id: this.uuidv4(),
          turbineModel: this.sortedTurbineModels?.filter(
            (t) => t?.id === pc.turbine_model_id,
          )[0],
          airDensity: pc.airdensity_kgpm3,
          powerCurve: {
            power: pc.vals.realpower_kw,
            windSpeed: pc.vals.windspeed_mps,
          },
        }));

        // Get list of turbine models from turbine table
        let turbineModelsClone = this.turbineModels;
        // Check if power curve table has a row for each turbine model
        // and if not add a new row for that turbine model
        var breakCheck = false;
        for (let j = 0; j < turbineModelsClone?.length; j++) {
          breakCheck = false;
          this.form.contractPowerCurve.forEach((pc) => {
            if (pc.turbineModel == turbineModelsClone[j]) {
              breakCheck = true;
            }
          });
          if (!breakCheck) {
            this.form.contractPowerCurve.push({
              id: this.uuidv4(),
              turbineModel: turbineModelsClone[j],
              airDensity: null,
              powerCurve: { windSpeed: [null], power: [null] },
            });
          }
        }
      }
    },
    setSiteData() {
      this.form = {
        // required
        org: this.orgs?.filter((org) => org?.id === this.siteData?.org_id)[0],
        site: this.siteData.name, // required
        siteAbbrev: this.siteData?.turbines[0]?.name.split("-")[1],
        orgAbbrev: this.siteData?.turbines[0]?.name.split("-")[0],
        latitude: this.siteData.latitude_deg, // required
        longitude: this.siteData.longitude_deg, // required
        powerPrice: this.powerPrice?.price_upper,
        powerPriceDate: new Date(this.powerPrice?.ts),
        powerPriceId: this.powerPrice?.id,
        notes: this.siteData.notes === null ? "" : this.siteData.notes,
        location: this.siteData.location,
        timeZone: this.siteData.time_zone,
        staleTime: this.siteData.scada_stale_time,
        staleTime10m: this.siteData.raw_10m_stale_time_hr,
        staleTimeEvent: this.siteData.event_stale_time_hr,
        controlSystem: null,
        windSensors: null,
        primarySecondary: null,
        parkController: null,
        metTowers: null,
        apm: null,
        highComponentFailures: null,
        curtailments: null,
        windSectorManagement: null,
        deratingProactively: null,
        repoweredSite: null,
        turbineCount: null,
        // eslint-disable-next-line no-undef
        turbines: structuredClone(this.siteData.turbines)?.map((turbine) => ({
          ...turbine,
          customer_name:
            // Everything after second dash,
            // {org_abbrev}-{site_abbrev}-{customer_name}
            turbine?.name?.match(/-/g)?.length > 1
              ? turbine?.name?.match(/(?<=([^-]*-){2}).*/)[0]
              : "",
          turbine_model: this.sortedTurbineModels?.filter(
            (t) => t?.id === turbine.turbine_model_id,
          )[0],
        })),
        subscriptions: this.siteData.subscriptions.map((subscription) => ({
          id: subscription?.id,
          subscription_type: {
            name: subscription?.name,
            sow_def_id: subscription?.sow_def_id,
          },
          startDate: new Date(subscription?.start_date),
          endDate: new Date(subscription?.end_date),
          notes: subscription?.notes,
        })),
        imputeRotorSpeed:
          this.siteData.analytics_settings?.impute_from_gen_speed == 1,
        gearboxRatio: this.siteData.analytics_settings?.gear_ratio,
        contractPowerCurveType: this.siteData.contract_power_curve_type,
        driveLink: null,
        state: this.siteData.state ? this.siteData.state : "inactive",
      };

      if (this.siteData.contract_power_curve_type === "turbine") {
        // eslint-disable-next-line no-undef
        this.form.contractPowerCurve = structuredClone(
          this.siteData,
        ).contract_power_curves.map((pc) => ({
          id: this.uuidv4(),
          name: pc.turbine_name,
          airDensity: pc.airdensity_kgpm3,
          powerCurve: {
            power: pc.vals.realpower_kw,
            windSpeed: pc.vals.windspeed_mps,
          },
        }));
      } else {
        // eslint-disable-next-line no-undef
        this.form.contractPowerCurve = structuredClone(
          this.siteData,
        ).contract_power_curves.map((pc) => ({
          id: this.uuidv4(),
          turbineModel: this.sortedTurbineModels?.filter(
            (t) => t?.id === pc.turbine_model_id,
          )[0],
          airDensity: pc.airdensity_kgpm3,
          powerCurve: {
            power: pc.vals.realpower_kw,
            windSpeed: pc.vals.windspeed_mps,
          },
        }));
      }
      this.powerCurveIsComplete = this.form.contractPowerCurve.length !== 0;

      if (
        this.form.powerPriceDate !== null &&
        this.form.powerPriceDate.toString() !== "Invalid Date"
      ) {
        this.siteInfoDateValid = true;
      }
      this.$nextTick(() => {
        this.changesMade = false;
      });
    },
    async save() {
      this.saveLoading = true;
      this.form.latitude = this.averageLatLong.averageLat;
      this.form.longitude = this.averageLatLong.averageLong;
      if (this.$route.name === "EditSite") {
        await this.updateSite({
          form: this.form,
          siteId: +this.$route.params.siteId,
          subscriptionsNotComplete: this.subscriptionsNotComplete,
          turbinesNotComplete: this.turbinesNotComplete,
          powerCurveNotComplete: this.powerCurveNotComplete(),
        });
      } else {
        await this.addNewSite({
          form: this.form,
          subscriptionsNotComplete: this.subscriptionsNotComplete,
          turbinesNotComplete: this.turbinesNotComplete,
          powerCurveNotComplete: this.powerCurveNotComplete(),
        });
        this.$router.push({ path: `/admin/sites/${this.siteResponse.id}` });
      }
      await this.getSidebarOrgs();
      this.saveLoading = false;
      this.readOnly = true;
    },
    cancel() {
      if (this.$route.name === "EditSite") {
        this.setSiteData();
      } else {
        this.form = {
          org: null,
          site: null,
          latitude: null,
          longitude: null,
          location: null,
          timeZone: null,
          powerPrice: null,
          powerPriceDate: new Date(),
          powerPriceId: null,
          notes: "",
          staleTime: null,
          staleTime10m: null,
          staleTimeEvent: null,
          controlSystem: null,
          windSensors: null,
          primarySecondary: null,
          parkController: null,
          metTowers: null,
          apm: null,
          highComponentFailures: null,
          curtailments: null,
          windSectorManagement: null,
          deratingProactively: null,
          repoweredSite: null,
          otherKnownIssues: null,
          hardwareUpgrades: null,
          softwareUpgrades: null,
          turbineCount: null,
          turbines: [],
          subscriptions: [],
          imputeRotorSpeed: false,
          gearboxRatio: null,
          contractPowerCurve: [
            {
              id: this.uuidv4(),
              airDensity: null,
              powerCurve: { power: [null], windSpeed: [null] },
              turbineModel: {
                name: "",
              },
            },
          ],
          contractPowerCurveType: "turbine-model",
          driveLink: null,
          state: "inactive",
        };
        this.powerCurveIsComplete = false;
      }
    },
    setPowerCurve() {
      if (this.form.contractPowerCurveType === "turbine-model") {
        let turbineModelsClone = this.turbineModels;
        // Check if power curve table has a row for each turbine model
        // and if not add a new row for that turbine model
        var turbineModelExists = false;
        for (let j = 0; j < turbineModelsClone?.length; j++) {
          turbineModelExists = false;
          this.form.contractPowerCurve.forEach((pc) => {
            if (pc.turbineModel.id == turbineModelsClone[j].id) {
              turbineModelExists = true;
            }
          });
          if (!turbineModelExists) {
            this.form.contractPowerCurve.push({
              id: this.uuidv4(),
              turbineModel: turbineModelsClone[j] || { name: "" },
              airDensity: null,
              powerCurve: { windSpeed: [null], power: [null] },
            });
          }
        }
        // If a turbine model is removed from turbine table also remove
        // it from power curve table
        if (turbineModelsClone?.length < this.form.contractPowerCurve?.length) {
          this.form.contractPowerCurve = this.form.contractPowerCurve.filter(
            ({ turbineModel }) => {
              return turbineModelsClone.some((x) => x?.id === turbineModel?.id);
            },
          );
        }
      }
      if (this.form.contractPowerCurveType === "turbine") {
        // Get list of turbines from turbine table
        // eslint-disable-next-line no-undef
        let turbinesClone = structuredClone(this.form.turbines);
        for (let i = 0; i < this.form.turbines?.length; i++) {
          for (let j = 0; j < this.form.contractPowerCurve?.length; j++) {
            if (
              turbinesClone[i]?.name == this.form.contractPowerCurve[j]?.name
            ) {
              turbinesClone.splice(i, 1);
            }
          }
        }
        // Check if power curve table has a row for each turbine
        // and if not add a new row for that turbine
        if (this.form.turbines?.length > this.form.contractPowerCurve?.length) {
          for (let i = 0; i < turbinesClone?.length; i++) {
            this.form.contractPowerCurve.push({
              id: this.uuidv4(),
              name: turbinesClone[i]?.name
                ? turbinesClone[i]?.name
                : this.setPCTurbineName(
                    this.form.orgAbbrev,
                    this.form.siteAbbrev,
                    turbinesClone[i].customer_name,
                  ),
              turbine: turbinesClone[i],
              airDensity: null,
              powerCurve: { windSpeed: [null], power: [null] },
            });
          }
        }
        // If a turbine is removed from the turbine table also remove
        // it from power curve table
        if (this.form.turbines?.length < this.form.contractPowerCurve?.length) {
          this.form.contractPowerCurve = this.form.contractPowerCurve.filter(
            ({ turbine }) => {
              return this.form.turbines.some((x) => x?.id === turbine?.id);
            },
          );
        }
      }
    },
    powerCurveNotComplete() {
      return (
        !this.form.contractPowerCurve?.length ||
        this.form.contractPowerCurve.some(
          (curve) =>
            !curve.airDensity ||
            curve.powerCurve.power.some(
              (power, index) =>
                power === null ||
                power === "" ||
                curve.powerCurve.windSpeed[index] === null ||
                curve.powerCurve.windSpeed[index] === "",
            ),
        )
      );
    },
    isPowerCurveComplete() {
      this.powerCurveIsComplete = !this.powerCurveNotComplete();
    },
    changeActivationLevel(event) {
      const setActiveRules = () => {
        this.rules.siteInfo.powerPrice = (value) =>
          !!value || `Power price is required.`;
        this.rules.turbines.customer_name = (value) =>
          !!value || "Turbine name is required";
        this.rules.turbines.abbrev = (value) =>
          (value && value.length >= 3 && value.length <= 5) ||
          "Must have 3 to 5 characters.";
        this.siteInfoDateValid = !(
          (this.form.powerPriceDate &&
            this.form.powerPriceDate?.toString() === "Invalid Date") ||
          !this.form.powerPriceDate
        );
      };
      const setInactiveRules = () => {
        this.form.state = "inactive";
        Object.keys(this.rules.siteInfo).forEach(
          (key) => (this.rules.siteInfo[key] = true),
        );
        Object.keys(this.rules.turbines).forEach(
          (key) => (this.rules.turbines[key] = true),
        );
        this.siteInfoDateValid = true;
      };

      if (event.includes("active-")) {
        setActiveRules();
      }

      if (event === "inactive") {
        setInactiveRules();
      }

      this.$refs.form.validate();
    },
    setPCTurbineName(orgAbbrev, siteAbbrev, turbineName) {
      const nameParts = [orgAbbrev, siteAbbrev, turbineName].filter(
        (part) => part,
      );
      return nameParts.join("-");
    },
    handleAbbrevBlur() {
      if (this.form.contractPowerCurve.length > 0) {
        for (const turbine of this.form.turbines) {
          if (!turbine.name) {
            turbine.name = this.setPCTurbineName(
              this.form.orgAbbrev,
              this.form.siteAbbrev,
              turbine.customer_name,
            );
          }
        }
      }
    },
    isWindspeedError(pcIndex, windSpeedIndex) {
      const isWindSpeedNegativeOrMonotonic =
        this.form.contractPowerCurve[pcIndex].powerCurve.windSpeed[
          windSpeedIndex
        ] < 0 ||
        (this.isMonotonic[pcIndex] && !this.isMonotonic[pcIndex].value);
      const isWindSpeedNull =
        this.form.contractPowerCurve[pcIndex].powerCurve.windSpeed[
          windSpeedIndex
        ] === null;
      const isEditable = !this.readOnly || (!this.isEdit && this.readOnly);
      const isActiveState =
        this.form.state === "active-calculations" ||
        this.form.state === "active-issue-detection";

      return (
        isEditable &&
        ((isWindSpeedNegativeOrMonotonic && !isActiveState) ||
          (isActiveState &&
            (isWindSpeedNegativeOrMonotonic || isWindSpeedNull)))
      );
    },
    isPowerError(pcIndex, powerIndex) {
      const power =
        this.form.contractPowerCurve[pcIndex].powerCurve.power[powerIndex];
      const isPowerInvalid = power < 0 || power > 20000 || power === null;
      const isEditable = !this.readOnly || (!this.isEdit && this.readOnly);
      const isActiveState =
        this.form.state === "active-calculations" ||
        this.form.state === "active-issue-detection";

      return (
        isEditable &&
        ((!isActiveState && power < 0) || (isActiveState && isPowerInvalid))
      );
    },
    isSubDateValid(date) {
      const isActiveOrEditable =
        this.form.state === "active-issue-detection" &&
        ((this.isEdit && !this.readOnly) || !this.isEdit);

      return (
        !isActiveOrEditable || (date && date.toString() !== "Invalid Date")
      );
    },
  },
  async beforeMount() {
    if (this.$route.name === "EditSite") {
      this.editLoading = true;
      const siteId = this.$route.params.siteId;
      await this.getSite(siteId);
      await this.getTurbineModels();
      await this.getPowerPrice(siteId);
      await this.getAllOrgs();
      this.setSiteData();
      this.editLoading = false;
    } else {
      for (const key in this.rules.siteInfo) {
        this.rules.siteInfo[key] = true;
      }
      await this.getAllOrgs();
      await this.getTurbineModels();
      this.form.state = "inactive";
    }
  },
  watch: {
    form: {
      handler() {
        this.changesMade = true;
        if (this.isEdit && !this.readOnly) {
          if (
            this.form.state.includes("active-") &&
            (this.form.powerPriceDate === null ||
              this.form.powerPriceDate.toString() === "Invalid Date")
          ) {
            this.siteInfoDateValid = false;
          } else {
            this.siteInfoDateValid = true;
          }
        }
      },
      deep: true,
      immediate: true,
    },
    contractPowerCurveType: {
      handler() {
        this.setPowerCurve();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.form_container {
  max-width: 85%;
  margin: 0;

  .mx-input {
    height: 40px;
  }
}
.powerprice.date-picker {
  width: 100%;
}
.error-highlight {
  .mx-input {
    border: 2px solid var(--v-error-base);
  }
}
.new-site-container label {
  margin-bottom: 0;
}

.v-expansion-panel-header--active .mdi-menu-down {
  transform: rotate(0deg) !important;
}
.turbine-upload dialog {
  top: 200px !important;
}
.abbrev-width {
  min-width: 35px;
}
.turbine-name {
  min-width: 250px;
}
.highlight-row {
  background-color: rgb(238, 238, 238);
}
.highlight-row-dark {
  background-color: var(--v-hover-base);
}
</style>
